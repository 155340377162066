<template>
  <b-table responsive class="datatable"
           bordered hover small :items="imported" :fields="fields">

  </b-table>
</template>

<script>
import XLSX from "xlsx";
import {post} from "@/services/api";

export default {
  name: "EcuOptions",
  props: {
    headers: Array,
    range: Number,
    url: String,
    name: String,
    transformer: {
      default: {},
      type: Object
    }
  },
  data: () => ({
    imported: [],
    key_errors: [],
    fields: []
  }),
  methods: {
    read(sheet) {
      const data = XLSX.utils.sheet_to_json(sheet,
        {range: this.range, raw: true, rawNumbers: false, blankrows: false, defval: null})
      if (data.length) {
        if (!this.checkHeaders(data[0])) {
          this.imported = data.map(row => {
            const formated = {};
            Object.keys(this.headers).forEach(key => {
              if (!row.hasOwnProperty(this.headers[key])) {
                console.error("key not found", key, this.headers[key])
              }
              if (this.transformer[key]) {
                formated[key] = this.transformer[key](row[this.headers[key]])
              } else {
                formated[key] = row[this.headers[key]]
              }
            })
            return formated
          }).filter(el => el.optionId !== '' && !isNaN(el.optionId))
        } else {
          this.$swal({
            icon: 'error',
            title: `Excel bad formated in Engine Soft Database: ${this.key_errors} headers, was not found`,
            showCancelButton: false,
            confirmButtonColor: "#d14343",
            timer: 5000
          })
        }
      }
    },
    checkHeaders(row) {
      const keys = Object.values(this.headers);
      keys.forEach(key => {
        if (!row.hasOwnProperty(key)) {
          this.key_errors.push(key);
        }
      });
      return this.key_errors.length
    },
    save() {
      return new Promise(resolve => {
        this.$swal({
          title: `Saving ${this.name}...`,
          didOpen: () => {
            this.$swal.showLoading()
          },
        })
        post(`${this.url}`, this.imported, true)
          .then(() => {
            this.$swal({
              icon: 'success',
              title: `${this.name} submitted successfully`,
              showConfirmButton: false,
              timer: 2500
            }).then(() => resolve(true))
          })
          .catch(() => {
            this.$swal({
              icon: 'error',
              title: `${this.name} submission failed`,
              showConfirmButton: false,
              timer: 2500
            }).then(() => resolve(false))
          })
      })
    },
    setFields() {
      for (const headersKey in this.headers) {
        this.fields.push({key: headersKey, label: this.headers[headersKey].split('_')[0]})
      }
    }
  },
  mounted() {
    this.setFields()
  }
}
</script>

<style scoped>

</style>
