<template>
  <div>
    <b-table responsive class="datatable"
             bordered hover small :items="imported" :fields="fields">

    </b-table>
  </div>
</template>

<script>
import XLSX from "xlsx";
import {post} from "@/services/api";

export default {
  name: "Vehicles",
  data: () => ({
    imported: [],
    headers: {
      make: 'Make',
      model: 'Model',
      generation: 'Generation',
      engine: 'Engine',
      engineCode: 'Engine Code',
      engineFamily: 'Engine Family',
      dsg: 'DSG Family',

      stockPower: 'Power [hp]',
      stockTorque: 'Torque [Nm]',

      stageOnePower: 'Power [hp]_1',
      stageOneTorque: 'Torque [Nm]_1',
      stageOneOne: 'Location availability',
      stageOneTwo: 'Engine software options',
      stageOnePrice: 'Price',

      stageTwoPower: 'Power [hp]_2',
      stageTwoTorque: 'Torque [Nm]_2',
      stageTwoOne: 'Location availability_1',
      stageTwoTwo: 'Engine software options_1',
      stageTwoThree: 'Hardware modifications',
      stageTwoPrice: 'Price_1',

      stageTwoPlusPower: 'Power [hp]_3',
      stageTwoPlusTorque: 'Torque [Nm]_3',
      stageTwoPlusOne: 'Location availability_2',
      stageTwoPlusTwo: 'Engine software options_2',
      stageTwoPlusThree: 'Hardware modifications_1',
      stageTwoPlusFour: 'Warnings',
      stageTwoPlusPrice: 'Price_2',

      stageThreePower: 'Power [hp]_4',
      stageThreeTorque: 'Torque [Nm]_4',
      stageThreeOne: 'Location availability_3',
      stageThreeTwo: 'Engine software options_3',
      stageThreeThree: 'Hardware modifications_2',
      stageThreeFour: 'Warnings_1',
      stageThreePrice: 'Price_3',

      stageFourPower: 'Power [hp]_5',
      stageFourTorque: 'Torque [Nm]_4',
      stageFourOne: 'Location availability_4',
      stageFourTwo: 'Engine software options_4',
      stageFourThree: 'Hardware modifications_3',
      stageFourFour: 'Warnings_2',
      stageFourPrice: 'Price_4',

      status: 'status',
      ToReview: 'Review'
    },
    transformer: {
      engineCode: (value) => value ? JSON.stringify(value.split(';').map(el => el.trim())) : []
    },
    key_errors: [],
    fields: []
  }),
  methods: {
    read(sheet) {
      const data = XLSX.utils.sheet_to_json(sheet,
        {raw: true, range: 4, rawNumbers: false, blankrows: true, defval: null});
      if (data.length) {
        if (!this.checkHeaders(data[0])) {
          this.imported = data.map(row => {
            const formated = {};
            Object.keys(this.headers).forEach(key => {
              if (this.transformer[key]) {
                formated[key] = this.transformer[key](row[this.headers[key]])
              } else {
                formated[key] = row[this.headers[key]]
              }
            })
            return formated
          }).filter(el => el.make !== null)
        } else {
          this.$swal({
            icon: 'error',
            title: `Excel bad formated in Engine Soft Database: ${this.key_errors} headers, was not found`,
            showCancelButton: false,
            confirmButtonColor: "#d14343",
            timer: 5000
          })
        }
      }

    },
    checkHeaders(row) {
      const keys = Object.values(this.headers);
      keys.forEach(key => {
        if (!row.hasOwnProperty(key)) {
          this.key_errors.push(key);
        } else {
        }
      });
      return this.key_errors.length
    },
    save() {
      return new Promise(resolve => {
        this.$swal({
          title: 'Saving vehicles...',
          didOpen: () => {
            this.$swal.showLoading()
          },
        })
        post(`vehicles/save`, this.imported, true)
          .then(() => {
            this.$swal({
              icon: 'success',
              title: `Vehicles submitted successfully`,
              showConfirmButton: false,
              timer: 2500
            }).then(() => resolve(true))
          })
          .catch(() => {
            this.$swal({
              icon: 'error',
              title: `Vehicles submission failed`,
              showConfirmButton: false,
              timer: 2500
            }).then(() => resolve(false))
          })
      })
    },
    setFields() {
      for (const headersKey in this.headers) {
        this.fields.push({key: headersKey, label: this.headers[headersKey].split('_')[0]})
      }
    }
  },
  mounted() {
    this.setFields();
  }
}
</script>

<style scoped>

</style>
