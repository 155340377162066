<template>
  <b-table responsive class="datatable"
           bordered hover small :items="imported" :fields="fields">

  </b-table>
</template>

<script>
import XLSX from "xlsx";
import {post} from "@/services/api";

export default {
  name: "VehiclesTCU",
  data: () => ({
    imported: [],
    headers: {
      DSG: 'DSG Family',
      prefijo: 'Store ALL pre letters here',

      stockTorque: 'Max Torque',
      stockLocation: 'Location availability',
      stockDsgSoftware: 'Software options',
      stockDsgWarnings: 'Warnings',
      stockPrice: 'Price',

      stageOneTorque: 'Max Torque_1',
      stageOneLocation: 'Location availability_1',
      stageOneDsgSoftware: 'Software options_1',
      stageOneDsgWarnings: 'Warnings_1',
      stageOnePrice: 'Price_1',

      stageTwoTorque: 'Max Torque_2',
      stageTwoLocation: 'Location availability_2',
      stageTwoDsgSoftware: 'Software options_2',
      stageTwoDsgWarnings: 'Warnings_2',
      stageTwoPrice: 'Price_2',

      stageTwoPlusTorque: 'Max Torque_3',
      stageTwoPlusLocation: 'Location availability_3',
      stageTwoPlusDsgSoftware: 'Software options_3',
      stageTwoPlusDsgHardware: 'Hardware modifications',
      stageTwoPlusDsgWarnings: 'Warnings_3',
      stageTwoPlusPrice: 'Price_3',

      stageThreeTorque: 'Max Torque_4',
      stageThreeLocation: 'Location availability_4',
      stageThreeDsgSoftware: 'Software options_4',
      stageThreeDsgHardware: 'Hardware modifications_1',
      stageThreeDsgWarnings: 'Warnings_4',
      stageThreePrice: 'Price_4',

      stageFourTorque: 'Max Torque_5',
      stageFourLocation: 'Location availability_5',
      stageFourDsgSoftware: 'Software options_5',
      stageFourDsgHardware: 'Hardware modifications_2',
      stageFourDsgWarnings: 'Warnings_5',
      stageFourPrice: 'Price_5',
      status: 'status'
    },
    key_errors: [],
    fields: []
  }),
  methods: {
    read(sheet) {
      const data = XLSX.utils.sheet_to_json(sheet,
        {raw: true, range: 3, rawNumbers: false, blankrows: true, defval: null})
      if (data.length) {
        if (!this.checkHeaders(data[0])) {
          this.imported = data.map(row => {
            const formated = {};
            Object.keys(this.headers).forEach(key => {
              if (!row.hasOwnProperty(this.headers[key])) {
                console.error("key not found", key, this.headers[key])
              }
              formated[key] = row[this.headers[key]]
            })
            return formated
          }).filter(el => el.DSG !== null)
        } else {
          this.$swal({
            icon: 'error',
            title: `Excel bad formated in Engine Soft Database: ${this.key_errors} headers, was not found`,
            showCancelButton: false,
            confirmButtonColor: "#d14343",
            timer: 5000
          })
        }
      }
    },
    checkHeaders(row) {
      const keys = Object.values(this.headers);
      keys.forEach(key => {
        if (!row.hasOwnProperty(key)) {
          this.key_errors.push(key);
        }
      });
      return this.key_errors.length
    },
    save() {
      return new Promise(resolve => {
        this.$swal({
          title: 'Saving TCU vehicles...',
          didOpen: () => {
            this.$swal.showLoading()
          },
        })
        post(`vehicles-dsg/save`, this.imported, true)
          .then(() => {
            this.$swal({
              icon: 'success',
              title: `TCU vehicles submited successfully`,
              showConfirmButton: false,
              timer: 2500
            }).then(() => resolve(true))
          })
          .catch(() => {
            this.$swal({
              icon: 'error',
              title: `TCU vehicles submission failed`,
              showConfirmButton: false,
              timer: 2500
            }).then(() => resolve(false))
          })
      })
    },
    setFields() {
      for (const headersKey in this.headers) {
        this.fields.push({key: headersKey, label: this.headers[headersKey].split('_')[0]})
      }
    }
  },
  mounted() {
    this.setFields()
  }
}
</script>

<style scoped>

</style>
