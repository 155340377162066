<template>
  <b-container class="main-content-container px-4" fluid>
    <b-row class="page-header py-4" no-gutters>
      <b-col class="text-center text-sm-left mb-4 mb-sm-0" col sm="12">
        <h3 class="page-title">Database excel importer</h3>
        <span class="text-uppercase page-subtitle"></span>
      </b-col>
    </b-row>
    <b-card body-class="p-0">
      <div class="form-group m-3 d-flex justify-content-between">
        <b-form-file v-model="file" @change="readFile" ref="input"/>
        <b-button @click="save" :disabled="thereIsData">Save</b-button>
      </div>
      <b-tabs justified class="custom-tab">
        <b-tab title="VEHICLES">
          <vehicles ref="vehicles"/>
        </b-tab>
        <b-tab title="ECU Options">
          <options ref="ecu_options" :range="0"
                   :headers="ecu_headers"
                   :transformer="df_headers_transformer"
                   url="vehicles-options/save"
                   name="ECU options"/>
        </b-tab>
        <b-tab title="ECU Sub-Options">
          <options ref="ecu_sub_options" :range="1"
                   :headers="ecu_sub_headers"
                   :transformer="ecu_sub_headers_transformer"
                   url="vehicles-secondary-options/save"
                   name="ECU secondary options"/>
        </b-tab>
        <b-tab title="TCU Vehicles">
          <VehiclesTCU ref="vehicles_tcu"/>
        </b-tab>
        <b-tab title="TCU Options">
          <options ref="tcu_options" :range="0"
                   :headers="tcu_headers"
                   :transformer="df_headers_transformer"
                   url="dsg-options/save"
                   name="TCU Options"/>
        </b-tab>
        <b-tab title="TCU Sub-Options">
          <options ref="tcu_sub_options" :range="1"
                   :headers="tcu_sub_headers"
                   url="dsg-options-two/save"
                   name="TCU secondary options"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </b-container>
</template>

<script>
import XLSX from "xlsx";
import Vehicles from "@/views/dashboard/vehicles-importer/components/Vehicles";
import Options from "@/views/dashboard/vehicles-importer/components/Options";
import VehiclesTCU from "@/views/dashboard/vehicles-importer/components/VehiclesTCU";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "VehiclesImporter",
  mixins:[show_alert_mixin],
  components: {VehiclesTCU, Options, Vehicles},
  data: () => ({
    file: null,
    ecu_headers: {
      optionId: 'ID',
      name: 'Location availability',
      description: 'Explanation',
      options: 'Option',
      type: 'Type',
      addNewOne: 'ADD NEW ONE',
      defaultOption: 'Default Option'
    },
    df_headers_transformer: {
      defaultOption: (value) => value ? JSON.stringify(value.toString().split(';').map(el => el.trim())) : null
    },
    ecu_sub_headers: {
      optionId: 'option_id',
      type: 'Option Type',
      description: 'Description',
      explanation: 'Explanation',
      engineCode: 'Engine Family',
      engine: 'Engine'
    },
    ecu_sub_headers_transformer: {
      engineCode: (value) => value ? JSON.stringify(value.toString().split(';').map(el => el.trim())) : null
    },
    tcu_headers: {
      optionId: 'ID',
      name: 'DSG options',
      description: 'Explanation',
      type: 'Type',
      options: 'Menu Type',
      newPrice: 'Price',
      addNewOne: 'Add New One',
      defaultOption: 'Default Option',
    },
    tcu_sub_headers: {
      optionId: 'option_id',
      type: 'Option Type',
      description: 'Description',
      explanation: 'Explanation',
      engineFamily: 'DSG Model',
      engine: 'Engine',
      extradata: 'Extra input'
    },
    thereIsData: true
  }),
  methods: {
    async readFile(e) {
      const file = e.target.files[0];
      if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        this.fileChosen = true;
        const reader = new FileReader();
        this.$swal({
          title: 'please wait...',
          customClass: 'small-swall',
          didOpen: () => {
            this.$swal.showLoading()
          },
          background: '#c9c9c9'
        });
        reader.onloadend = () => {
          const arrayBuffer = reader.result;
          const workbook = XLSX.read(arrayBuffer, {type: 'array'});
          this.$refs.vehicles.read(workbook.Sheets['ENGINE SOFT DATABASE']);
          this.$refs.ecu_options.read(workbook.Sheets['ENGINE SOFT DB OPTIONS']);
          this.$refs.ecu_sub_options.read(workbook.Sheets['ENGINE SOFT DB MULTIPLE CHO']);
          this.$refs.vehicles_tcu.read(workbook.Sheets['DSG SOFT DATABASE'])
          this.$refs.tcu_options.read(workbook.Sheets['DSG SOFT DB OPTIONS']);
          this.$refs.tcu_sub_options.read(workbook.Sheets['DSG SOFT MULTIPLE CHOICE OPTION']);
          this.$swal.close();
          this.checkThereIsData()
          this.file = null;
        }
        reader.readAsArrayBuffer(file)
      } else {
        this.showAlertNotification('File not permitted')
      }
    },
    async save() {
      const vehicles = await this.$refs.vehicles.save();
      const ecu_options = await this.$refs.ecu_options.save();
      const ecu_sub_options = await this.$refs.ecu_sub_options.save();
      const vehicles_tcu = await this.$refs.vehicles_tcu.save();
      const tcu_options = await this.$refs.tcu_options.save();
      const tcu_sub_options = await this.$refs.tcu_sub_options.save();
      this.$swal({
        title: 'Import summary',
        html: `<ul class="list-group">
                <li class="list-group-item">Vehicles: <i class="material-icons">${vehicles ? 'check_circle' : 'cancel'}</i></li>
                <li class="list-group-item">ECU Option: <i class="material-icons">${ecu_options ? 'check_circle' : 'cancel'}</i></li>
                <li class="list-group-item">ECU Sub-options: <i class="material-icons">${ecu_sub_options ? 'check_circle' : 'cancel'}</i></li>
                <li class="list-group-item">TCU Vehicles: <i class="material-icons">${vehicles_tcu ? 'check_circle' : 'cancel'}</i></li>
                <li class="list-group-item">TCU Options: <i class="material-icons">${tcu_options ? 'check_circle' : 'cancel'}</i></li>
                <li class="list-group-item">TCU Sub-options: <i class="material-icons">${tcu_sub_options ? 'check_circle' : 'cancel'}</i></li>
              </ul>`,
        confirmButtonColor: '#d14343'
      })
    },
    checkThereIsData() {
      if (Object.keys(this.$refs).length) {
        this.thereIsData = !this.$refs.vehicles.imported.length
          && !this.$refs.ecu_options.imported.length
          && !this.$refs.ecu_sub_options.imported.length
          && !this.$refs.vehicles_tcu.imported.length
          && !this.$refs.tcu_options.imported.length
          && !this.$refs.tcu_sub_options.imported.length;
      } else {
        this.thereIsData = true;
      }
    }
  },
}
</script>

<style lang="scss">
.small-swall {
  .sub-title {
    font-size: 1.2em;
  }

  &.swal2-popup {
    padding: 0.45em;
    width: 20em;
  }

  .swal2-actions {
    margin: 0.25em auto 0;
  }
}
</style>
